export type Status =
  | 'Open'
  | 'Covered'
  | 'Covered - Add-on candidate'
  | 'Conflict'
  | 'Dead'
  | 'Portfolio - Active'
  | 'Portfolio - Inactive'

export const COVERAGE_STATUSES = [
  'Open',
  'Covered',
  'Covered - Add-on candidate',
  'Conflict',
  'Dead',
  'Portfolio - Active',
  'Portfolio - Inactive',
] as const

export const PRIORITIES = ['Top', 'High', 'Medium', 'Low', 'Dead'] as const

export type Sector =
  | 'Digital Media'
  | 'eCommerce & Retail'
  | 'Industrial Technologies'
  | 'Infrastructure'
  | 'Software & Services'
  | 'Related Firms'

export const SECTORS = [
  'Digital Media',
  'eCommerce & Retail',
  'Industrial Technologies',
  'Infrastructure',
  'Software & Services',
  'Related Firms',
] as const

export const SUB_SECTORS = [
  'Advertising Technology',
  'Content',
  'Gaming',
  'Informative Service',
  'Other - Digital Media',
  'B2B',
  'B2C',
  'MarketPlace',
  'Other - eCommerce & Retail',
  'Automation - Robotics',
  'Infrastructure',
  'Instrumentation',
  'Pumps - Level - Flow',
  'Sensors',
  'Services',
  'Other - Industrial Technologies',
  'Communication Service',
  'Compute',
  'Management & Monitoring',
  'Networking',
  'Security',
  'Semiconductor / Chips',
  'Service Provider Infrastructure',
  'Storage',
  'Other - Infrastructure',
  'FinTech',
  'Horizontal SW (ERP, CRM, SCM)',
  'Mobile',
  'Vertical SW (Education, Healthcare)',
  'Other - Software',
  'Accounting Firm',
  'Banker',
  'Consulting Firm',
  'Debt Provider',
  'Law Firm',
  'PE Firm',
  'VC Firm',
  'Large Corporate',
] as const

export const SECTOR_MAPPING = {
  'Digital Media': [
    'Advertising Technology',
    'Content',
    'Gaming',
    'Informative Service',
    'Other - Digital Media',
  ] as const,
  'eCommerce & Retail': [
    'B2B',
    'B2C',
    'MarketPlace',
    'Other - eCommerce & Retail',
  ] as const,
  'Industrial Technologies': [
    'Automation - Robotics',
    'Infrastructure',
    'Instrumentation',
    'Pumps - Level - Flow',
    'Sensors',
    'Services',
    'Other - Industrial Technologies',
  ] as const,
  Infrastructure: [
    'Communication Service',
    'Compute',
    'Management & Monitoring',
    'Networking',
    'Security',
    'Semiconductor / Chips',
    'Service Provider Infrastructure',
    'Storage',
    'Other - Infrastructure',
  ] as const,
  'Software & Services': [
    'FinTech',
    'Horizontal SW (ERP, CRM, SCM)',
    'Mobile',
    'Vertical SW (Education, Healthcare)',
    'Other - Software',
  ] as const,
  'Related Firms': [
    'Accounting Firm',
    'Banker',
    'Consulting Firm',
    'Debt Provider',
    'Law Firm',
    'PE Firm',
    'VC Firm',
    'Large Corporate',
  ] as const,
} as const

export const COUNTRIES = [
  'United States',
  'Argentina',
  'Australia',
  'Austria',
  'Belgium',
  'Brazil',
  'Canada',
  'China',
  'Czech Republic',
  'Denmark',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'India',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'Luxembourg',
  'Mexico',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Poland',
  'Portugal',
  'Russia',
  'Singapore',
  'South Korea',
  'Spain',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Turkey',
  'United Kingdom',
  'Unknown',
  'Other - Africa',
  'Other - Asia/Pacific',
  'Other - Caribbean',
  'Other - Europe',
  'Other - Middle East',
  'Other - N. America',
  'Other - S. America',
] as const

export const STATES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
  'N/A',
] as const

export const STATE_NAME_MAP: { [key: string]: string } = {
  alabama: 'AL',
  alaska: 'AK',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',
} as const

export const COUNTRY_NAME_MAP: { [key: string]: string } = {
  afghanistan: 'AF',
  albania: 'AL',
  algeria: 'DZ',
  andorra: 'AD',
  angola: 'AO',
  'antigua and barbuda': 'AG',
  argentina: 'AR',
  armenia: 'AM',
  australia: 'AU',
  austria: 'AT',
  azerbaijan: 'AZ',
  bahamas: 'BS',
  bahrain: 'BH',
  bangladesh: 'BD',
  barbados: 'BB',
  belarus: 'BY',
  belgium: 'BE',
  belize: 'BZ',
  benin: 'BJ',
  bhutan: 'BT',
  bolivia: 'BO',
  'bosnia and herzegovina': 'BA',
  botswana: 'BW',
  brazil: 'BR',
  brunei: 'BN',
  bulgaria: 'BG',
  'burkina faso': 'BF',
  burundi: 'BI',
  'cabo verde': 'CV',
  cambodia: 'KH',
  cameroon: 'CM',
  canada: 'CA',
  'central african republic': 'CF',
  chad: 'TD',
  chile: 'CL',
  china: 'CN',
  colombia: 'CO',
  comoros: 'KM',
  'congo (congo-brazzaville)': 'CG',
  'costa rica': 'CR',
  croatia: 'HR',
  cuba: 'CU',
  cyprus: 'CY',
  'czechia (czech republic)': 'CZ',
  'democratic republic of the congo': 'CD',
  denmark: 'DK',
  djibouti: 'DJ',
  dominica: 'DM',
  'dominican republic': 'DO',
  ecuador: 'EC',
  egypt: 'EG',
  'el salvador': 'SV',
  'equatorial guinea': 'GQ',
  eritrea: 'ER',
  estonia: 'EE',
  eswatini: 'SZ',
  ethiopia: 'ET',
  fiji: 'FJ',
  finland: 'FI',
  france: 'FR',
  gabon: 'GA',
  gambia: 'GM',
  georgia: 'GE',
  germany: 'DE',
  ghana: 'GH',
  greece: 'GR',
  grenada: 'GD',
  guatemala: 'GT',
  guinea: 'GN',
  'guinea-bissau': 'GW',
  guyana: 'GY',
  haiti: 'HT',
  honduras: 'HN',
  hungary: 'HU',
  iceland: 'IS',
  india: 'IN',
  indonesia: 'ID',
  iran: 'IR',
  iraq: 'IQ',
  ireland: 'IE',
  israel: 'IL',
  italy: 'IT',
  jamaica: 'JM',
  japan: 'JP',
  jordan: 'JO',
  kazakhstan: 'KZ',
  kenya: 'KE',
  kiribati: 'KI',
  kuwait: 'KW',
  kyrgyzstan: 'KG',
  laos: 'LA',
  latvia: 'LV',
  lebanon: 'LB',
  lesotho: 'LS',
  liberia: 'LR',
  libya: 'LY',
  liechtenstein: 'LI',
  lithuania: 'LT',
  luxembourg: 'LU',
  madagascar: 'MG',
  malawi: 'MW',
  malaysia: 'MY',
  maldives: 'MV',
  mali: 'ML',
  malta: 'MT',
  'marshall islands': 'MH',
  mauritania: 'MR',
  mauritius: 'MU',
  mexico: 'MX',
  micronesia: 'FM',
  moldova: 'MD',
  monaco: 'MC',
  mongolia: 'MN',
  montenegro: 'ME',
  morocco: 'MA',
  mozambique: 'MZ',
  'myanmar (burma)': 'MM',
  namibia: 'NA',
  nauru: 'NR',
  nepal: 'NP',
  netherlands: 'NL',
  'new zealand': 'NZ',
  nicaragua: 'NI',
  niger: 'NE',
  nigeria: 'NG',
  'north korea': 'KP',
  'north macedonia': 'MK',
  norway: 'NO',
  oman: 'OM',
  pakistan: 'PK',
  palau: 'PW',
  'palestine state': 'PS',
  panama: 'PA',
  'papua new guinea': 'PG',
  paraguay: 'PY',
  peru: 'PE',
  philippines: 'PH',
  poland: 'PL',
  portugal: 'PT',
  qatar: 'QA',
  romania: 'RO',
  russia: 'RU',
  rwanda: 'RW',
  'saint kitts and nevis': 'KN',
  'saint lucia': 'LC',
  'saint vincent and the grenadines': 'VC',
  samoa: 'WS',
  'san marino': 'SM',
  'sao tome and principe': 'ST',
  'saudi arabia': 'SA',
  senegal: 'SN',
  serbia: 'RS',
  seychelles: 'SC',
  'sierra leone': 'SL',
  singapore: 'SG',
  slovakia: 'SK',
  slovenia: 'SI',
  'solomon islands': 'SB',
  somalia: 'SO',
  'south africa': 'ZA',
  'south korea': 'KR',
  'south sudan': 'SS',
  spain: 'ES',
  'sri lanka': 'LK',
  sudan: 'SD',
  suriname: 'SR',
  sweden: 'SE',
  switzerland: 'CH',
  syria: 'SY',
  taiwan: 'TW',
  tajikistan: 'TJ',
  tanzania: 'TZ',
  thailand: 'TH',
  'timor-leste': 'TL',
  togo: 'TG',
  tonga: 'TO',
  'trinidad and tobago': 'TT',
  tunisia: 'TN',
  turkey: 'TR',
  turkmenistan: 'TM',
  tuvalu: 'TV',
  uganda: 'UG',
  ukraine: 'UA',
  'united arab emirates': 'AE',
  'united kingdom': 'GB',
  'united states of america': 'US',
  uruguay: 'UY',
  uzbekistan: 'UZ',
  vanuatu: 'VU',
  'vatican city': 'VA',
  venezuela: 'VE',
  vietnam: 'VN',
  yemen: 'YE',
  zambia: 'ZM',
  zimbabwe: 'ZW',
} as const

export const TEAMS = [
  {
    id: 1,
    members: [
      {
        name: 'Neeraj Agrawal',
        salesforceUserId: '005700000014eemAAA',
        leader: true,
      },
      {
        name: 'Brandon Gleklen',
        salesforceUserId: '00570000004L9VuAAK',
        leader: true,
      },
      {
        name: 'Sudhee Chilappagari',
        salesforceUserId: '0055x00000BFNPrAAP',
        leader: true,
      },
      {
        name: 'Mikey Hoeksema',
        salesforceUserId: '00539000006C3gSAAS',
        leader: true,
      },
      {
        name: 'Jack Mattei',
        salesforceUserId: '0055x00000AV7q3AAD',
        leader: false,
      },
      {
        name: 'Olivia Henkoff',
        salesforceUserId: '005f2000006yHqEAAU',
        leader: false,
      },
      {
        name: 'Hailey Wilcox',
        salesforceUserId: '0055x00000BqBTIAA3',
        leader: false,
      },
      {
        name: 'Matt Dailey',
        salesforceUserId: '0055x00000Blhi5AAB',
        leader: false,
      },
      {
        name: 'Sholape Fashemo',
        salesforceUserId: '0055x00000BlhE5AAJ',
        leader: false,
      },
    ],
  },
  {
    id: 2,
    members: [
      {
        name: 'Dharmesh Thakker',
        salesforceUserId: '00570000003vHCoAAM',
        leader: true,
      },
      {
        name: 'Barak Schoster',
        salesforceUserId: '0055x00000BklauAAB',
        leader: true,
      },
      {
        name: 'Danel Dayan',
        salesforceUserId: '005f2000006xrt9AAA',
        leader: true,
      },
      {
        name: 'Sudhee Chilappagari',
        salesforceUserId: '0055x00000BFNPrAAP',
        leader: true,
      },
      {
        name: 'Jason Mendel',
        salesforceUserId: '0055x00000AVATvAAP',
        leader: true,
      },
      {
        name: 'Patrick Hsu',
        salesforceUserId: '0055x00000C0xOLAAZ',
        leader: false,
      },
      {
        name: 'Payal Modi',
        salesforceUserId: '0055x00000BqBTDAA3',
        leader: false,
      },
    ],
  },
  {
    id: 3,
    members: [
      {
        name: 'Morad Elhafed',
        salesforceUserId: '00570000001FCZgAAO',
        leader: true,
      },
      {
        name: 'Satoshi Harris-Koizumi',
        salesforceUserId: '00570000001U9txAAC',
        leader: true,
      },
      {
        name: 'Peter Winans',
        salesforceUserId: '0055x00000BRrLhAAL',
        leader: false,
      },
      {
        name: 'Megan Larmann',
        salesforceUserId: '0055x00000C12WQAAZ',
        leader: false,
      },
      {
        name: 'Grace Hermes',
        salesforceUserId: '0055x00000C1FS8AAN',
        leader: false,
      },
      {
        name: 'Becca Jones',
        salesforceUserId: '0055x00000BzhoNAAR',
        leader: false,
      },
    ],
  },
  {
    id: 4,
    members: [
      {
        name: 'Michael Brown',
        salesforceUserId: '005700000012bDzAAI',
        leader: true,
      },
      {
        name: 'Dallin Bills',
        salesforceUserId: '00570000004L9VfAAK',
        leader: true,
      },
      {
        name: 'Aaron Neil',
        salesforceUserId: '0055x00000BF3chAAD',
        leader: true,
      },
      {
        name: 'Adam Piasaki',
        salesforceUserId: '0055x00000BlGHQAA3',
        leader: false,
      },
      {
        name: 'Aditya Modiak',
        salesforceUserId: '0055x00000C18PLAAZ',
        leader: false,
      },
      {
        name: 'Ethan Child',
        salesforceUserId: '005Pg000003NFhNIAW',
        leader: false,
      },
    ],
  },
  {
    id: 5,
    members: [
      {
        name: 'Chelsea Stoner',
        salesforceUserId: '005700000014efIAAQ',
        leader: true,
      },
      {
        name: 'Dillon Joyce',
        salesforceUserId: '00539000005NU9JAAW',
        leader: true,
      },
      {
        name: 'Alexander Hyldmar',
        salesforceUserId: '0055x00000C01HxAAJ',
        leader: false,
      },
      {
        name: 'Gavin Tasker',
        salesforceUserId: '0055x00000C06YtAAJ',
        leader: false,
      },
      {
        name: 'Evan Labuda',
        salesforceUserId: '005Pg00000AR6GTIA1',
        leader: false,
      },
      {
        name: 'MacKenzie Chapman',
        salesforceUserId: '0055x00000C1F67AAF',
        leader: false,
      },
      {
        name: 'Mason Parker',
        salesforceUserId: '0055x00000C1F62AAF',
        leader: false,
      },
    ],
  },
  {
    id: 6,
    members: [
      {
        name: 'Dave Tabors',
        salesforceUserId: '005700000014efJAAQ',
        leader: true,
      },
      {
        name: 'Collier Searle',
        salesforceUserId: '0055x00000BUMqdAAH',
        leader: true,
      },
      {
        name: 'George Christopher',
        salesforceUserId: '0055x00000BRxEnAAL',
        leader: false,
      },
      {
        name: 'Noah Spiesz',
        salesforceUserId: '0055x00000C0pC2AAJ',
        leader: false,
      },
    ],
  },
  {
    id: 7,
    members: [
      {
        name: 'Marcus Ryu',
        salesforceUserId: '0055x00000C1MARAA3',
        leader: true,
      },
      {
        name: 'Enzo Wiener',
        salesforceUserId: '0055x00000Bq4P9AAJ',
        leader: false,
      },
    ],
  },
  {
    id: 8,
    members: [
      {
        name: 'Russell Fleischer',
        salesforceUserId: '00570000003eWrdAAE',
        leader: true,
      },
      {
        name: 'Roland Anderson',
        salesforceUserId: '00570000003d3yiAAA',
        leader: true,
      },
      {
        name: 'Dawit Workie',
        salesforceUserId: '005f2000008uefPAAQ',
        leader: true,
      },
      {
        name: 'Ali Mobtaker',
        salesforceUserId: '0055x00000BqBT3AAN',
        leader: false,
      },
      {
        name: 'Matt Benda',
        salesforceUserId: '005Pg000009Ybb3IAC',
        leader: false,
      },
    ],
  },
  {
    id: 9,
    members: [
      {
        name: 'Roger Lee',
        salesforceUserId: '005700000012bEEAAY',
        leader: true,
      },
      {
        name: 'Courtney Chow',
        salesforceUserId: '005f2000006xkd7AAA',
        leader: true,
      },
      {
        name: 'Isabel von Stauffenberg',
        salesforceUserId: '0055x00000AV7qDAAT',
        leader: false,
      },
    ],
  },
  {
    id: 10,
    members: [
      {
        name: 'Jesse Feldman',
        salesforceUserId: '005700000014eezAAA',
        leader: true,
      },
      {
        name: 'Zack Smotherman',
        salesforceUserId: '00570000002NhWEAA0',
        leader: true,
      },
      {
        name: 'Justin Rosner',
        salesforceUserId: '00570000003d3ysAAA',
        leader: true,
      },
      {
        name: 'Max Kaye',
        salesforceUserId: '00570000003d3ydAAA',
        leader: true,
      },
      {
        name: 'Stefan Momic',
        salesforceUserId: '0055x00000Bzbf7AAB',
        leader: false,
      },
      {
        name: 'Jack McGuinness',
        salesforceUserId: '0055x00000C0xNmAAJ',
        leader: false,
      },
      {
        name: 'Bridget Bernardo',
        salesforceUserId: '0055x00000C0pC7AAJ',
        leader: false,
      },
      {
        name: 'Peyton Lane',
        salesforceUserId: '005Pg000009YbUbIAK',
        leader: false,
      },
      {
        name: 'Page Wolfenden',
        salesforceUserId: '0055x00000BlhEFAAZ',
        leader: false,
      },
      {
        name: 'Russell Oppenheim',
        salesforceUserId: '005Pg00000FvPfPIAV',
        leader: false,
      },
    ],
  },
  {
    id: 11,
    members: [
      {
        name: 'Zakary Ewen',
        salesforceUserId: '00570000001HhoiAAC',
        leader: true,
      },
      {
        name: 'Antonia Heiss',
        salesforceUserId: '005f200000A7PwFAAV',
        leader: false,
      },
      {
        name: 'Luis-Lucas de Haas',
        salesforceUserId: '0055x00000C04MMAAZ',
        leader: false,
      },
      {
        name: 'Estelle Tessaro',
        salesforceUserId: '005Pg000005d1W1IAI',
        leader: false,
      },
      {
        name: 'Andrea Riganti',
        salesforceUserId: '005Pg000005d1KjIAI',
        leader: false,
      },
      {
        name: 'Thomas Marchioni',
        salesforceUserId: '005Pg000006AlS1IAK',
        leader: false,
      },
      {
        name: 'Moritz Heim',
        salesforceUserId: '005Pg00000AFePBIA1',
        leader: false,
      },
    ],
  },
  {
    id: 12,
    members: [
      {
        name: 'Shiran Shalev',
        salesforceUserId: '00570000002LxpDAAS',
        leader: true,
      },
      {
        name: 'Lior Mallul',
        salesforceUserId: '005f2000007D9XUAA0',
        leader: true,
      },
      {
        name: 'Nikki Hassan',
        salesforceUserId: '0055x00000C18UVAAZ',
        leader: false,
      },
    ],
  },
  {
    id: 13,
    members: [
      {
        name: 'Jordan Welu',
        salesforceUserId: '005700000032syoAAA',
        leader: true,
      },
      {
        name: 'Lauren Wedell',
        salesforceUserId: '005f2000007D9XZAA0',
        leader: false,
      },
      {
        name: 'Victoria Mayzlish',
        salesforceUserId: '0055x00000C19o7AAB',
        leader: false,
      },
      {
        name: 'Katie Solarz',
        salesforceUserId: '0055x00000C163wAAB',
        leader: false,
      },
      {
        name: 'Megan Cardosi',
        salesforceUserId: '005Pg00000AR61xIAD',
        leader: false,
      },
    ],
  },
]
