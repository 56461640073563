import { createCompany } from '@/company/company.controller'
import AutoComplete from '@/components/AutoComplete'
import ExternalLink from '@/components/ExternalLink'
import { UnknownCompanyInfo } from '@/types'
import {
  COUNTRIES,
  COVERAGE_STATUSES,
  PRIORITIES,
  Sector,
  SECTOR_MAPPING,
  SECTORS,
  STATES,
  Status,
} from '@/types/constants'
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import { Button, Input, Loader, useCombobox } from '@mantine/core'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useFormState, useFormStatus } from 'react-dom'

const SubmitButton = ({ label }: { label: string }) => {
  const { pending } = useFormStatus()

  return (
    <Button
      color="rgb(0, 42, 58)"
      type="submit"
      w="100%"
      disabled={pending}
      className="flex gap-2 justify-center"
      rightSection={
        pending && <Loader color="rgb(0, 42, 58)" type="dots" size={14} />
      }
    >
      {label}
    </Button>
  )
}

export default function CreateCompanyForm({
  unknownCompanyInfo,
  standalone = false,
  companyOrgId,
  hostname,
  onComplete,
}: {
  unknownCompanyInfo: UnknownCompanyInfo | null
  standalone?: boolean
  hostname: string
  companyOrgId?: string
  onComplete?: (success: boolean, title: string, message?: string) => void
}) {
  const [
    { errors, done, success, title, message, duplicateCompanyMatches },
    formAction,
  ] = useFormState(createCompany, {
    errors: {},
    done: false,
    success: false,
    title: '',
    message: '',
    duplicateCompanyMatches: [],
  })
  const [sector, setSector] = useState(unknownCompanyInfo?.sector || '')
  const [status, setStatus] = useState<Status>()
  const [country, setCountry] = useState(unknownCompanyInfo?.country || '')

  const isUs = country.toLowerCase() === 'united states'
  const domain = hostname.replaceAll('www.', '').toLowerCase()
  const isDuplicateError = duplicateCompanyMatches.length > 0

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const [candidateToSalesforceId, setCandidateToSalesforceId] = useState('')
  const [candidateToName, setCandidateToName] = useState('')

  useEffect(() => {
    if (done && success && onComplete) {
      onComplete(success, title, message)
    }
  }, [done, message, onComplete, success, title])

  if (standalone && done && success) {
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold">Company Created. Please close.</h1>
      </div>
    )
  }

  return (
    <form className="p-4 flex flex-col gap-3" action={formAction}>
      <h1 className="capitalize font-bold text-3xl text-battery-triangle text-center">
        Create Company
      </h1>

      <input hidden name="companyOrgId" defaultValue={companyOrgId || ''} />

      {unknownCompanyInfo?.goToBid && (
        <div className="bg-yellow-100 p-2 rounded-sm shadow-sm">
          <h1 className="font-semibold flex gap-2 items-center">
            <ExclamationTriangleIcon className="w-4 h-4 text-yellow-500" />
            Company currently up for bid
          </h1>
          <p className="text-sm">
            The default coverage status has been set to &quot;open&quot;
          </p>
        </div>
      )}

      <Input.Wrapper label="Company Name" required error={errors.companyName}>
        <Input
          name="companyName"
          error={errors.companyName}
          defaultValue={unknownCompanyInfo?.name}
        />
      </Input.Wrapper>

      <Input.Wrapper
        label="Coverage Status"
        required
        error={errors.coverageStatus}
      >
        <Input
          name="coverageStatus"
          component="select"
          defaultValue={unknownCompanyInfo?.goToBid ? 'Open' : 'Covered'}
          pointer
          onChange={(e) => setStatus(e.currentTarget.value as Status)}
          rightSection={<ChevronDownIcon className="h-4 w-4" />}
          error={errors.coverageStatus}
        >
          <option value="">Please Select</option>
          {COVERAGE_STATUSES.map((coverageStatus) => (
            <option key={coverageStatus} value={coverageStatus}>
              {coverageStatus}
            </option>
          ))}
        </Input>
      </Input.Wrapper>

      <div
        className={clsx('block', {
          hidden: status !== 'Covered - Add-on candidate',
        })}
      >
        <AutoComplete
          withAsterisk={status === 'Covered - Add-on candidate'}
          inputLabel="Candidate To"
          searchType="company"
          onSubmit={(name: string, salesforceId: string) => {
            setCandidateToSalesforceId(salesforceId)
            setCandidateToName(name)
          }}
          onClear={() => {
            setCandidateToSalesforceId('')
            setCandidateToName('')
          }}
          keyAccessor="salesforceId"
          valueAccessor="salesforceId"
          labelAccessor="name"
          inputPlaceholder="Search"
          error={errors.candidateTo}
        />
        <input
          readOnly
          hidden
          name="candidateTo"
          value={candidateToSalesforceId || ''}
        />
        <input
          readOnly
          hidden
          name="candidateToName"
          value={candidateToName || ''}
        />
      </div>

      <Input.Wrapper label="Priority" error={errors.priority}>
        <Input
          name="priority"
          component="select"
          defaultValue=""
          pointer
          rightSection={<ChevronDownIcon className="h-4 w-4" />}
          error={errors.priority}
        >
          <option value="">Please Select</option>
          {PRIORITIES.map((priority) => (
            <option key={priority} value={priority}>
              {priority}
            </option>
          ))}
        </Input>
      </Input.Wrapper>

      <Input.Wrapper label="Sector" required error={errors.sector}>
        <Input
          name="sector"
          component="select"
          pointer
          rightSection={<ChevronDownIcon className="h-4 w-4" />}
          onChange={(e) => setSector(e.currentTarget.value as Sector)}
          error={errors.sector}
          defaultValue={unknownCompanyInfo?.sector}
        >
          <option value="">Please Select</option>
          {SECTORS.map((sector) => (
            <option key={sector} value={sector}>
              {sector}
            </option>
          ))}
        </Input>
      </Input.Wrapper>

      <Input.Wrapper
        label="Sub-Sector"
        required
        error={errors.subSector}
        className={clsx('block', {
          hidden: !sector,
        })}
      >
        <Input
          defaultValue={unknownCompanyInfo?.subSector}
          error={errors.subSector}
          name="subSector"
          component="select"
          pointer
          rightSection={<ChevronDownIcon className="h-4 w-4" />}
        >
          {sector &&
            SECTOR_MAPPING[sector as Sector].map((subSector) => (
              <option key={subSector} value={subSector}>
                {subSector}
              </option>
            ))}
        </Input>
      </Input.Wrapper>

      <Input.Wrapper label="Website" required error={errors.website}>
        <Input name="website" defaultValue={domain} error={errors.website} />
      </Input.Wrapper>

      <Input.Wrapper label="Country" required error={errors.country}>
        <Input
          name="country"
          component="select"
          pointer
          rightSection={<ChevronDownIcon className="h-4 w-4" />}
          onChange={(e) => setCountry(e.currentTarget.value)}
          error={errors.country}
          defaultValue={unknownCompanyInfo?.country}
        >
          <option value="">Please Select</option>
          {COUNTRIES.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </Input>
      </Input.Wrapper>

      <Input.Wrapper
        label="State"
        error={errors.state}
        className={clsx('block', {
          hidden: !isUs,
        })}
        required={isUs}
      >
        <Input
          name="state"
          component="select"
          pointer
          rightSection={<ChevronDownIcon className="h-4 w-4" />}
          defaultValue={unknownCompanyInfo?.state}
          error={errors.state}
        >
          <option value="">Please Select</option>
          {STATES.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Input>
      </Input.Wrapper>

      <Input.Wrapper label="Business Description" error={errors.description}>
        <Input
          name="description"
          error={errors.description}
          defaultValue={unknownCompanyInfo?.description}
        />
      </Input.Wrapper>

      {done && !success && !isDuplicateError && (
        <p className="text-sm flex flex-col text-red-400">
          <span className="text-center">{title}</span>
          <span>{message}</span>
        </p>
      )}

      <input
        hidden
        type="checkbox"
        name="forceCreate"
        checked={isDuplicateError}
        readOnly
      />

      {done && !success && isDuplicateError && (
        <div>
          <h1 className="text-red-400 text-center font-bold">
            {duplicateCompanyMatches.length} Possible Duplicate Record Found
          </h1>
          <p className="text-red-400">
            You&apos;re creating a duplicate record. We recommend you use an
            existing record instead.
          </p>
          <ol className="list-disc list-inside">
            {duplicateCompanyMatches.map(({ id, name }) => {
              return (
                <li key={id}>
                  <ExternalLink
                    href={`https://batteryventures.my.salesforce.com/${id}`}
                    className="underline underline-offset-2 text-battery-triangle"
                  >
                    {name}
                  </ExternalLink>
                </li>
              )
            })}
          </ol>
        </div>
      )}

      <SubmitButton
        label={isDuplicateError ? 'Create (Ignore Duplicate)' : 'Create'}
      />
    </form>
  )
}
